
.active-item {
  background-color:#1371FF;

  &:hover{
    color:#000;
  }
  
}

.active-item-app {
  background-color:#004ec4;

  &:hover{
    color:#000;
  }
  
}


